import API from "@/api";

export default {
  // 账号密码登录
  loginByPsd: params => {
    return API.requestPost({
      data: params,
      url: "admin/loginByAccount"
    });
  },
  // 验证码登录
  loginByCode: params => {
    return API.requestPost({
      data: params,
      url: "admin/loginByMobile"
    });
  },
  // 发送验证码
  getCode: params => {
    return API.requestPost(
      {
        url: "/api/sms/sendSms",
        data: params
      },
      false
    );
  },
  // 获取权限菜单
  menu: params => {
    return API.requestPost({
      url: "role/getRoleByUserId",
      data: params
    });
  }
};
