const list = [
  { name: "控制台", path: "/control", index: "1", icon: "el-icon-s-platform" },
  {
    name: "用户管理",
    index: "2",
    icon: "el-icon-user",
    path: "",
    children: [
      { name: "商户列表", path: "/merchants", index: "2-1" },
      { name: "所有用户", path: "/users", index: "2-2" },
      { name: "未处理", path: "/untreated", index: "2-3" },
      { name: "已通过", path: "/passList", index: "2-4" },
      { name: "已拒绝", path: "/refusedList", index: "2-5" },
      { name: "黑名单", path: "/blacklist", index: "2-6" }
    ]
  },
  {
    name: "财务统计",
    index: "3",
    path: "",
    icon: "el-icon-reading",
    children: [
      { name: "充值记录", path: "/charge", index: "4-1" },
      { name: "查询明细", path: "/reportDetail", index: "4-2" }
    ]
  },
  {
    name: "账号管理",
    index: "4",
    path: "",
    icon: "el-icon-user-solid",
    children: [
      { name: "角色管理", path: "/role", index: "5-1" },
      { name: "账号列表", path: "/list", index: "5-2" }
    ]
  },
  {
    name: "业务推广",
    index: "5",
    path: "",
    icon: "el-icon-share",
    children: [
      { name: "推广专员", path: "/persons", index: "6-1" },
      { name: "专员业绩", path: "/perform", index: "6-2" },
      { name: "商户推广", path: "/merchant", index: "6-3" }
    ]
  },
  {
    name: "数据统计",
    index: "6",
    path: "",
    icon: "el-icon-pie-chart",
    children: [
      { name: "认证统计", path: "/approve", index: "7-1" },
      { name: "统计报表", path: "/statement", index: "7-2" },
      { name: "接口统计", path: "/third", index: "7-3" }
    ]
  },
  {
    name: "系统设置",
    index: "7",
    path: "",
    icon: "el-icon-s-tools",
    children: [
        { name: "意见反馈", path: "/setting/feedBack", index: "8-1" },
        { name: "更新管理", path: "/setting/updateSetting", index: "8-2" },
        { name: "标签设置", path: "/setting/tagSetting", index: "8-3" }
      ]
  },
  {
    name: "操作日志",
    index: "8",
    path: "/operateLog",
    icon: "el-icon-document"
  },
  {
    name: "更新记录",
    index: "9",
    path: "/updateRecord",
    icon: "el-icon-date"
  }
];

export default {
  list
};
